<template>
  <form
    @submit.prevent="handleSubmit"
    id="artist-edit-activity-form"
    novalidate
    ref="form"
    class="artist-emboarding-activity__form-wrapper">
    <Select
      v-model="activity"
      :options="activityOptions"
      :label="`${$t('artist.activity.artisticActivity')}*`"
      :placeholder="$t('artist.activity.description')"
      isAutofocus
      required
      id="activity-select"
      class="mb-md">
    </Select>
    <Checkbox
      v-model="isChildrenAllowed"
      :label="$t('artist.activity.childrenAllowed')"
      id="children-permission">
    </Checkbox>
    <Paragraph
      :size="'sm'"
      class="mb-lg ml-lg">
      {{ $t('artist.activity.childrenAllowedDetails') }}
    </Paragraph>

    <Loader
      v-if="isLoading"
      class="mt-auto">
    </Loader>
    <Button
      v-else
      type="submit"
      class="mt-auto mr-auto">
      {{ $t('common.save') }}
    </Button>
  </form>
</template>

<script>

import {
  mapState,
  mapMutations,
  mapActions
}                                 from 'vuex';

import Loader                     from '../../../components/atoms/Loader/a-Loader.vue';
import Button                     from '../../../components/atoms/Button/a-Button.vue';
import Paragraph                  from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Select                     from '../../../components/atoms/Select/a-Select.vue';
import Checkbox                   from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import ACTIVITY_OPTIONS           from '../../../constants/activity-options.js';


export default {
  name: 'o-ArtistEmboardingActivity',
  components: {
    Loader,
    Button,
    Paragraph,
    Select,
    Checkbox,
  },
  data: () => ({
    activityOptions: ACTIVITY_OPTIONS,
    activity: '',
    isChildrenAllowed: false,
    isLoading: false,
    errors: [],
  }),
  computed: {
    ...mapState('User', ['user']),
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
    }),
    ...mapActions({
      editActivity: 'User/EDIT_ACTIVITY',
      editIdentity: 'User/EDIT_IDENTITY',
    }),
    handleCheckFormValidity() {
      const isFormValid = this.$refs.form.checkValidity();

      return isFormValid;
    },
    async handleSubmit() {
      if (!this.handleCheckFormValidity()) return;

      const identityParams = {
        input: {
          ChildrenPermission: this.isChildrenAllowed ? 'CHILDREN_ALLOWED' : 'CHILDREN_UNALLOWED',
        },
      };
      const activityParams = {
        input: {
          [this.activity]: {
            Activity: this.activity.toUpperCase(),
          },
        },
      };

      try {
        this.isLoading = true;

        await this.editActivity(activityParams);
        await this.editIdentity(identityParams);

        this.$router.push({ name: 'ArtistEmboardingWelcome' });
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
        this.$emit('error');
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.artist-emboarding-activity {
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and ($desktop) {
      flex: 0;
      align-self: start;
      min-width: 350px;
    }
  }

  &__error-message {
    color: var(--color-alert);
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
    font-size: var(--text-xs);
  }
}

</style>
